angular.module("app")
    .component('organisationAccounts', {
        templateUrl: '/static/templates/accounts/accounts.html',
        controller: Accounts,
        controllerAs: 'ctrl'
    });

function Accounts($log, $state, $stateParams, accountService, currentOrganisation, departmentService) {

    var vm = this;
    vm.accountingOwnerReference = undefined;
    vm.accounts = undefined;
    vm.loading = true;
    vm.readOnly = true;

    vm.$onInit = init;
    vm.setAccount = setAccount;
    vm.go = go;

    function init() {

        currentOrganisation.getOrganisationReference()
            .then(function(organisationReference) {

                departmentService.get(organisationReference)
                    .then(function (result) {

                        var organisation = result;

                        vm.accountingOwnerReference = organisation.accountingOwnerReference;

                        var params = {
                            limit: 2,
                            offset: 0
                        };

                        accountService.getAccounts(vm.accountingOwnerReference, params)
                            .then(function(response) {
                                vm.accounts = response.data.data;
                                if(!_.isEmpty(vm.accounts)) {
                                    vm.account = vm.accounts[0];
                                    go('accounts.transaction');
                                }
                            }, function(error) {
                                $log.debug(error);
                            });

                    }, function(error) {
                        $log.error(error);
                    })
                    ['finally'](function () {
                    vm.loading = false;
                });



        });
    }

    function go(ref) {
        $state.go(ref, {accountingOwnerReference: vm.accountingOwnerReference, accountReference: vm.account.accountReference});
    }

    function setAccount(account) {
        vm.account = account;
        go('accounts.transaction');
    }
}